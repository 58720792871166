<style lang="scss">
$width800: 800px;
.prprodukte {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
    .content-link-modal {
      -webkit-transition: all 500ms ease;
      cursor: pointer;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      display: block;
      text-align: center;
      padding: 10px;
      background: $blue;
      color: #fff;
      margin-bottom: 45px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .vorteile {
    margin: 20px 0;
    text-align: center;
    .nr {
      margin-bottom: 10px;
      text-align: center;
      display: block;
      position: relative;
      i {
        position: absolute;
        left: 0;
        top: 0;
        color: $blue-darker;
        font-size: 60px;
        z-index: 1;
        left: 50%;
        margin-left: -29px;
        top: 23px;
        @media (max-width: $width-lg) {
        }
      }
      @media (max-width: $width-lg) {
        display: block;
      }
      span {
        display: inline-block;
        color: #fff;
        position: relative;
        z-index: 2;
        line-height: 50px;
        width: 50px;
        font-size: 100%;
      }
    }
    h5 {
      text-align: center;
      display: inline-block;
      font-weight: 300;
      padding: 0 15px;
      @media (max-width: $width-md) {
        margin-bottom: 20px;
      }
      @media (max-width: 600px) {
        display: block;
        margin-left: 0;
        text-align: center;
      }
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
}
.info-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 120%;
  padding: 0 50px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
}
.btn-big {
  display: block;
  margin: 10px 0;
  text-align: center;
  margin-top: 30px;
  a {
    display: inline-block;
    padding: 15px 20px;
    background: $blue;
    color: #fff !important;
    &:hover {
      opacity: 0.9;
    }
  }
}

.erfolgbsp {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    display: block;

    position: relative;
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      background-color: rgba($color: #fff, $alpha: 0.8);
    }
  }
}

.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    .small {
      @media (max-width: 1370px) and (min-width: 1200px) {
        display: block;
      }
    }
    @media (max-width: 768px) {
      display: block;
      width: 100%;

      text-align: center;
    }
  }
  .inner-content {
    .headline {
      font-weight: 400;
    }
    .vorteile {
      margin-bottom: 40px;
      .col-lg-4 {
        justify-content: flex-start !important;
        align-self: flex-start !important;
      }
    }
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $blue-darker;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $blue-darker;
      }
    }
  }
  .breaker {
    display: block;
    height: 1px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .highlight-txt {
    background: $blue;
    padding: 15px;
    color: #fff;
    a {
      color: $blue-dark !important;
    }
  }
}
</style>

<template>
  <div class="content prprodukte container">
    <h1>PR-Produkte Digital</h1>
    <p class="intro-txt">Verfolgen Sie mit Ihrer Werbestrategie die Stärkung Ihrer Marke und deren Image? Haben Sie ein neues Produkt, dessen Markenpräsenz Sie ausweiten möchten? Steht für Sie erfolgreiche Kundenbindung im Vordergrund oder wollen Sie Bedürfnisse und Emotionen bei Ihrer Zielgruppe wecken?</p>
    <p>Mit maßgeschneiderten Inhalten und zielgruppenrelevanter Sprache bietet die Kleine Zeitung für jedes Ihrer Werbeziele das passende digitale PR-Produkt.</p>

    <h2>Individuelle Content-Lösungen</h2>
    <p>Nutzen Sie unser umfangreiches Portfolio, um Ihr Werbeziel optimal zu erreichen.</p>
    <TabGroup>
      <Tab tabid="prpdodukt-tab1" :title="'Sponsored Story'" active>
        <div class="inner-content">
          <div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>lange Verweildauer durch Storytelling</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>aktivierend und glaubwürdig</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>bleibt lange in Erinnerung und <span class="breaker"></span> wirkt nachhaltig</h5>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="leistung">
                <strong>Leistung</strong>
                <ul class="content-ul">
                  <li>Ausführliches Briefing</li>
                  <li>Projektmanagement</li>
                  <li>Content-Produktion im redaktionellen Look &amp; Feel <small>(zwei Korrekturschleifen im Preis enthalten)</small></li>
                  <li>5.000 garantierte Leser durch Bewerbung der Sponsored Story auf allen Plattformen von kleinezeitung.at und in den sozialen Netzwerken</li>
                </ul>
                <p>Vorlaufzeit: 5 Werktage</p>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/prprodukte/sponsored-story.png" alt="Sponsored Story" class="img-fluid" />
            </div>
          </div>

          <div class="preis-box">
            <span>Tarif: 4.985,00</span>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 20% Umsatzsteuer.</div>
        </div>
        <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
        <div class="row erfolgbsp justify-content-center align-self-center">
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/advertorials/sponsored/5605186/Sanieren-Entsorgen_Peter-Marie-und-ihr-fast-fertiges-Paradies" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v5.jpg" alt="Peter, Marie und ihr fast fertiges Paradies" class="img-fluid shadow" />
              <span>Saubermacher</span>
            </a>
          </div>
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/advertorials/sponsored/5538991/Pensionsvorsorge_Keine-Angst-vor-der-Zukunft" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v6.jpg" alt="Keine Angst vor der Zukunft" class="img-fluid shadow" />
              <span>Raiffeisen Versicherung</span>
            </a>
          </div>
        </div>
        <div class="btn-big"><a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/" target="_blank">Mehr Infos zu Native Advertising</a></div>
      </Tab>
      <Tab tabid="prpdodukt-tab2" :title="'Advertorial'">
        <div class="inner-content">
          <div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>Unternehmen/Produkt steht im Vordergrund</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>maßgeschneiderter<span class="breaker"></span> PR-Text</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>auffällig und reichweitenstark</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="leistung">
                <strong>Leistung</strong>
                <ul class="content-ul">
                  <li>Content-Produktion inkl. Bildersuche</li>
                  <li>Korrektorat <small>(zwei Korrekturschleifen im Preis enthalten)</small></li>
                  <li>Anlage des Artikels auf kleinezeitung.at</li>
                </ul>
                <p>Vorlaufzeit: 5 Werktage</p>
                <p><small>Damit Ihr Text bei der gewünschten Zielgruppe ankommt, empfehlen wir einen Content Teaser mit mind. 10.000 Sichtkontakten.</small></p>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/prprodukte/advertorial.png" alt="Advertorial" class="img-fluid" />
            </div>
          </div>
          <div class="preis-box">
            <span>Tarif: 429,00</span>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 20% Umsatzsteuer.</div>
        </div>
        <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
        <div class="row erfolgbsp">
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/advertorials/5888248/" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v1.jpg" alt="Mit Yoga und Energetik neue Kraft schöpfen" class="img-fluid shadow" />
              <span>Die Zeitoase</span>
            </a>
          </div>
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/advertorials/5880956/" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v2.jpg" alt="ie Ausbildung für Sport- und Radfans" class="img-fluid shadow" />
              <span>Zweirad Haimlinger</span>
            </a>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab3" :title="'Online-Abbildung <span class=\'small\'>Print PR-Artikel</span>'">
        <div class="inner-content">
          <div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>Unternehmen/Produkt steht im Vordergrund</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>maßgeschneiderter<span class="breaker"></span> PR-Text</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>auffällig und reichweitenstark</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="leistung">
                <strong>Leistung</strong>
                <ul class="content-ul">
                  <li>Content-Adaptierung des Print PR-Artikels inkl. Bildersuche</li>
                  <li>Korrektorat <small>(eine Korrekturschleifen im Preis enthalten)</small></li>
                  <li>Anlage des Artikels auf kleinezeitung.at</li>
                </ul>
                <p>Vorlaufzeit: 3 Werktage</p>
                <p>
                  <small>Nur in Kombination mit einem <router-link :to="{ name: 'ContentTeaserPerformancePaket' }" class="content-link">Content-Teaser</router-link> buchbar. Mindestanzahl Sichtkontakte: 5.000</small>
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/prprodukte/themenspecial.png" alt="Themenspecial" class="img-fluid" />
            </div>
          </div>

          <div class="preis-box">
            <span>Tarif: 225,00</span>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 20% Umsatzsteuer.</div>
        </div>
        <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
        <div class="row erfolgbsp">
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/advertorials/5888248/" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v1.jpg" alt="Mit Yoga und Energetik neue Kraft schöpfen" class="img-fluid shadow" />
              <span>Die Zeitoase</span>
            </a>
          </div>
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/advertorials/5880956/" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v2.jpg" alt="ie Ausbildung für Sport- und Radfans" class="img-fluid shadow" />
              <span>Zweirad Haimlinger</span>
            </a>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab4" :title="'Themenspecial'">
        <div class="inner-content">
          <div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>umfassende Informationen über ein Thema</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>reichweitenstark Kompetenz zeigen</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>wirkungsvolle Wunschdomain</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="leistung">
                <strong>Leistung</strong>
                <ul class="content-ul">
                  <li>Erstellung des Themenspecials mit max. 10 Artikeln inkl. Content-Produktion und Bildersuche</li>
                  <li>Korrektorat und Anlage der Artikel <small>(drei Korrekturschleifen im Preis enthalten)</small></li>
                  <li>Einrichtung der Übersichtsseite sowie einer eigenen Subdomain auf kleinezeitung.at</li>
                </ul>
                <p>Vorlaufzeit: 10 Werktage</p>
                <div class="highlight-txt">
                  Nur in Kombination mit einem <router-link :to="{ name: 'ContentTeaserPerformancePaket' }" class="content-link">Content-Teaser</router-link> buchbar. <br />
                  Mindestanzahl Sichtkontakte: 10.000 <br />
                  Alternativ kann zum Themenspecial auch ein <router-link :to="{ name: 'ContentTeaserPerformancePaket' }" class="content-link">Performance-Paket</router-link> gebucht werden.
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/prprodukte/themenspecial.png" alt="Themenspecial" class="img-fluid" />
            </div>
          </div>

          <div class="preis-box">
            <span>Tarif: 2.890,00</span>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 20% Umsatzsteuer.</div>
        </div>
        <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
        <div class="row erfolgbsp">
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/service/backenistzumteilenda/index.do" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v3.jpg" alt="Backen ist zum teilen da." class="img-fluid shadow" />
              <span>Farina</span>
            </a>
          </div>
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/service/amt_kaerntner_landesregierung/index.do" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v4.jpg" alt="Jubiläumsausstellung" class="img-fluid shadow" />
              <span>Amt der Kärnter Landesregierung</span>
            </a>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab5" :title="'Online-Abbildung <span class=\'small\'>Print-Druckstrecke</span>'">
        <div class="inner-content">
          <div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>umfassende Informationen über ein Thema</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>reichweitenstark Kompetenz zeigen</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>wirkungsvolle Wunschdomain</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="leistung">
                <strong>Leistung</strong>
                <ul class="content-ul">
                  <li>Content-Adaptierung der Print-Druckstrecke inkl. Bildersuche</li>
                  <li>Korrektorat und Anlage der Artikel auf kleinezeitung.at <small>(eine Korrekturschleife im Preis enthalten)</small></li>
                  <li>Einrichtung der Übersichtsseite sowie einer eigenen Subdomain auf kleinezeitung.at</li>
                </ul>
                <p>Vorlaufzeit: 3 Werktage</p>
                <p><small>Nur in Kombination mit einem Content-Teaser buchbar. Mindestanzahl Sichtkontakte: 10.000</small></p>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/prprodukte/themenspecial.png" alt="Themenspecial" class="img-fluid" />
            </div>
          </div>

          <div class="preis-box">
            <span>Tarif: 1.250,00</span>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 20% Umsatzsteuer.</div>
        </div>
        <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
        <div class="row erfolgbsp">
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/service/backenistzumteilenda/index.do" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v3.jpg" alt="Backen ist zum teilen da." class="img-fluid shadow" />
              <span>Backen ist zum Teilen da</span>
            </a>
          </div>
          <div class="col-md-6">
            <a href="https://www.kleinezeitung.at/service/amt_kaerntner_landesregierung/index.do" target="_blank">
              <img src="@/assets/img/img-content/sonderprodukte/prprodukt-digital-bsp-v4.jpg" alt="Jubiläumsausstellung" class="img-fluid shadow" />
              <span>Jubiläumsausstellung</span>
            </a>
          </div>
        </div>
      </Tab>
    </TabGroup>
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/prprodukte/DIGITALE-PR-Produkte-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif PR-Produkte Digital
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>799 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'PRProdukteDigital',
  components: {
    TabGroup,
    Tab,
  },
});
</script>
